<!--
  Title        : 商品詳細
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div style="color: white;text-align: left">
            <h2 class="introduction">{{ goods_name }}</h2>
            <div class="introduction">商品の詳細を選択してください。</div>
        </div>

        <div class="main">
            ▼{{ goods_name }}のベースとなるデザインを下記の項目より、選択・入力してください。<br>
            ※選択・入力された内容によって見積もり金額が変わります。
        </div>

        <div class="main">
            <div>
                <div class="select" v-show="goods_name == 'タスキ' || goods_name == '巾着' || goods_name == 'タオル' || goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ'">
                    <h2>生地を選ぶ</h2>
                    <div class="our_firm" v-show="goods_name == 'タオル'">
                        <carousel
                                :per-page="2"
                                :pagination-enabled="true"
                                :pagination-size="8"
                                :pagination-padding="5">
                            <slide v-for="(towel_material, material_num) in towel_material" :key="material_num">
                                <viewer class="viewer">
                                    <img id="img"
                                         :src="towel_material.img"
                                         :class="{ 'selected': selected_towel_material == towel_material.type, 'not-selected': selected_towel_material != towel_material.type }"
                                         @click="selectTowelMaterial(towel_material.type)">
                                </viewer>
                            </slide>
                        </carousel>
                    </div>

                    <div class="our_firm" v-show="goods_name == 'タスキ' ||goods_name == '巾着' || goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ'">
                        <carousel
                                :per-page="2"
                                :pagination-enabled="true"
                                :pagination-size="8"
                                :pagination-padding="5">
                            <slide v-for="(material, material_num) in material" :key="material_num">
                                <viewer class="viewer">
                                    <img id="img"
                                         :src="material.img"
                                         :class="{'selected': selected_material == material.type, 'not-selected': selected_material != material.type}"
                                         @click="selectMaterial(material.type)">
                                </viewer>
                            </slide>
                        </carousel>
                    </div>
                </div>

                <div class="select" v-show="goods_name == 'タオル'">
                    <h2>柄を選ぶ</h2>
                    <div class="our_firm">
                        <carousel
                                :per-page="2"
                                :pagination-enabled="true"
                                :pagination-size="8"
                                :pagination-padding="5">
                            <slide v-for="(pattern, pattern_num) in pattern" :key="pattern_num">
                                <viewer class="viewer">
                                    <img id="img"
                                         :src="pattern.img"
                                         :class="{ 'selected': selected_pattern === pattern.type, 'not-selected': selected_pattern !== pattern.type }"
                                         @click="selectPattern(pattern.type)">
                                </viewer>
                            </slide>
                        </carousel>
                    </div>
                    <div class="color_detail">
                        ※生地を選ぶで「シャーリング」が選択されている場合のみ、柄を選ぶで「全面柄」を選択することができます。
                    </div>
                </div>

                <div class="select" v-show="goods_name == 'タスキ'">
                    <h2>加工方法を選ぶ</h2>
                    <div class="our_firm">
                        <carousel
                                :per-page="2"
                                :pagination-enabled="true"
                                :pagination-size="8"
                                :pagination-padding="5">
                            <slide v-for="(machining, machining_num) in machining" :key="machining_num">
                                <viewer class="viewer">
                                    <img id="img"
                                         :src="machining.img"
                                         :class="{ 'selected': selected_machining == machining.type, 'not-selected': selected_machining != machining.type }"
                                         @click="selectMachining(machining.type)">
                                </viewer>
                            </slide>
                        </carousel>
                    </div>
                </div>

                <div class="select" v-show="goods_name == '団扇'">
                    <h2>種類を選ぶ</h2>
                    <div class="our_firm">
                        <carousel
                                :per-page="2"
                                :pagination-enabled="true"
                                :pagination-size="8"
                                :pagination-padding="5">
                            <slide v-for="(fan, fan_type_num) in fan_type" :key="fan_type_num">
                                <viewer class="viewer">
                                    <img id="img"
                                         :src="fan.img"
                                         :class="{ 'selected': selected_fan == fan.type, 'not-selected': selected_fan != fan.type }"
                                         @click="selectFanType(fan.type)">
                                </viewer>
                            </slide>
                        </carousel>
                    </div>

                    <div class="input_form">
                        <v-ons-select id="color" name="type" v-model="type">
                            <option name="type">1本巻き</option>
                            <option name="type" v-show="size == '普通サイズ' || size == ''">2本巻き</option>
                        </v-ons-select>
                    </div>
                    <div class="color_detail">
                        ※普通サイズのみ2本巻きにすることが可能です。
                    </div>
                </div>

                <div class="select" v-show="goods_name == 'タスキ房'">
                    <h2>色数</h2>
                    <div class="input_form">
                        <v-ons-select id="color" name="bunch_color" v-show="goods_name == 'タスキ房'" v-model="color">
                            <option name="bunch_color">白</option>
                            <option name="bunch_color">カラー</option>
                            <option name="bunch_color">白 ＋ カラー</option>
                            <option name="bunch_color">カラー ＋ カラー</option>
                        </v-ons-select>
                    </div>
                    <div class="color_detail">
                        ※色数でお値段が変わります。
                    </div>
                    <div class="validate" v-show="color_validate">
                        色数を指定してください
                    </div>
                </div>

                <div class="select" v-show="goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ' || goods_name == 'ロンパース' || goods_name == 'タスキ房' || goods_name == '団扇'">
                    <h2>サイズ</h2>
                    <div class="input_form">
                        <v-ons-select id="size" name="happi_coat_size" v-show="goods_name == '法被' || goods_name == 'チョッキ'" v-model="size">
                            <option name="happi_coat_size">S</option>
                            <option name="happi_coat_size">M</option>
                            <option name="happi_coat_size">L</option>
                            <option name="happi_coat_size">XL</option>
                            <option name="happi_coat_size">別注</option>
                        </v-ons-select>

                        <v-ons-select id="size" name="koikuchi_shirt_size" v-show="goods_name == '鯉口シャツ'" v-model="size">
                            <option name="koikuchi_shirt_size">110</option>
                            <option name="koikuchi_shirt_size">120</option>
                            <option name="koikuchi_shirt_size">130</option>
                            <option name="koikuchi_shirt_size">140</option>
                            <option name="koikuchi_shirt_size">150</option>
                            <option name="koikuchi_shirt_size">160</option>
                            <option name="koikuchi_shirt_size">S</option>
                            <option name="koikuchi_shirt_size">M</option>
                            <option name="koikuchi_shirt_size">L</option>
                            <option name="koikuchi_shirt_size">XL</option>
                            <option name="koikuchi_shirt_size">2XL</option>
                            <option name="koikuchi_shirt_size">別注</option>
                        </v-ons-select>

                        <v-ons-select id="size" name="rompers_size" v-show="goods_name == 'ロンパース'" v-model="size">
                            <option name="rompers_size">70</option>
                            <option name="rompers_size">80</option>
                            <option name="rompers_size">90</option>
                        </v-ons-select>

                        <v-ons-select id="size" name="bunch_size" v-show="goods_name == 'タスキ房'" v-model="size">
                            <option name="bunch_size">3寸</option>
                            <option name="bunch_size">4寸</option>
                        </v-ons-select>

                        <v-ons-select id="size" name="fan_size" v-show="goods_name == '団扇'" v-model="size" @change="adjustType">
                            <option name="fan_size">普通サイズ</option>
                            <option name="fan_size">ミニサイズ</option>
                        </v-ons-select>
                    </div>
                    <div class="validate" v-show="size_validate">
                        サイズを選択してください
                    </div>
                </div>

                <div class="select">
                    <h2>数量</h2>
                    <div class="input_form">
                        <v-ons-input class="input" type="number" inputmode="numeric" name="volume" v-show="goods_name !== 'タオル'" v-model="volume"/>
                    </div>
                    <div>
                        <v-ons-select id="size" name="volume" v-show="goods_name == 'タオル'" v-model="volume">
                            <option name="volume">100</option>
                            <option name="volume">200</option>
                            <option name="volume">300</option>
                            <option name="volume">400</option>
                            <option name="volume">500</option>
                            <option name="volume">600</option>
                            <option name="volume">700</option>
                            <option name="volume">800</option>
                            <option name="volume">900</option>
                            <option name="volume">1000</option>
                        </v-ons-select>
                    </div>
                    <div class="volume_detail" v-show="goods_name == 'タオル'">
                        タオルは100枚からお選びいただけます。
                    </div>
                    <div class="validate" v-show="volume_validate">
                        数量を入力してください
                    </div>
                </div>
            </div>
            <div class="base">
                <div class="base_detail">
                    <div>単価(税抜)</div>
                    <h2 id="base">
                        ￥{{ base }}
                    </h2>
                </div>
            </div>
            <div class="amount">
                <div class="amount_detail">
                    <div>お見積り金額(税抜)</div>
                    <h2 id="amount">
                        ￥{{ calculateAmount() }}
                    </h2>
                </div>
                <v-ons-button
                        :class="{ iPhoneBtn:terminal === 'iPhone', AndroidBtn:terminal === 'Android' }"
                        @click="PageTransition('userInformation')"
                        v-show="true">
                    ご注文手続き
                </v-ons-button>
            </div>
        </div>

        <!--  ローディングダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertLoadingVisible">
            <Spinner message="データ取得中...." />
        </v-ons-alert-dialog>

    </v-ons-page>
</template>

<script>
    import Toolbar from '../../Common/Toolbar';
    import BottomToolbar from "../../Common/BottomToolbar";
    import Spinner from "vue-simple-spinner";

    export default {
        name: "GoodsDetails",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
            Spinner
        },
        data(){
            return{
                terminal: this.$_Terminal(),

                goods_name: '',
                type: '1本巻き',
                color: '',
                size: '',
                volume: 1,
                base: '',
                amount: '',
                towel_material: [
                    {
                        img: '/img/販売管理/タオル生地/シャーリング.jpg',
                        type: 'シャーリング'
                    },
                    {
                        img: '/img/販売管理/タオル生地/片毛.jpg',
                        type: '片毛'
                    },
                    {
                        img: '/img/販売管理/タオル生地/総パイル.jpg',
                        type: '総パイル'
                    },
                ],
                selected_towel_material: 'シャーリング',
                selected_towel_material_img: '/img/販売管理/タオル生地/シャーリング.jpg',
                pattern: [
                    {
                        img: '/img/販売管理/柄/全面柄.jpg',
                        type: '全面柄'
                    },
                    {
                        img: '/img/販売管理/柄/中ベタ柄.jpg',
                        type: '中ベタ柄'
                    },
                    {
                        img: '/img/販売管理/柄/一般柄.jpg',
                        type: '一般柄'
                    },
                ],
                selected_pattern: '全面柄',
                selected_pattern_img: '/img/販売管理/柄/全面柄.jpg',
                material: [
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/chirimen.png',
                        type: 'チリメン'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/onichirimen.png',
                        type: 'オニチリメン'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/hubuki.png',
                        type: '吹雪'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/rinkou.png',
                        type: '綸光'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/takeda.png',
                        type: '竹田菱'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/saten.png',
                        type: 'サテン'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/nakaasa.png',
                        type: '中麻'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/small_saya.png',
                        type: '小サヤ柄'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/saya.png',
                        type: 'サヤ柄'
                    },
                    {
                        img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/middle_saya.png',
                        type: '中サヤ柄'
                    },
                ],
                selected_material: 'チリメン',
                selected_material_img: 'https://technoeye-danjiri.s3.ap-northeast-1.amazonaws.com/goods/material/chirimen.png',
                machining: [
                    {
                        img: '/img/販売管理/加工方法/肉盛り刺繡.jpg',
                        type: '肉盛り刺繍'
                    },
                    {
                        img: '/img/販売管理/加工方法/刺繍.jpg',
                        type: '刺繍'
                    },
                    {
                        img: '/img/販売管理/加工方法/貼り付け.jpg',
                        type: '貼り付け'
                    },
                    {
                        img: '/img/販売管理/加工方法/プリント.jpg',
                        type: 'プリント'
                    },
                ],
                selected_machining: '肉盛り刺繍',
                selected_machining_img: '/img/販売管理/加工方法/肉盛り刺繡.jpg',
                fan_type: [
                    {
                        img: '/img/販売管理/団扇種類/昇華団扇.jpg',
                        type: '昇華団扇'
                    },
                    {
                        img: '/img/販売管理/団扇種類/フルカラー団扇.jpg',
                        type: 'フルカラー団扇'
                    },
                ],
                selected_fan: '昇華団扇',
                selected_fan_img: '/img/販売管理/団扇種類/昇華団扇.jpg',

                color_validate: false,
                size_validate: false,
                volume_validate: false,

                alertLoadingVisible: false,
            }
        },
        created(){
            this.goods_name = this.$_GoodsDetail().goods_name
            switch(this.goods_name){
                case "タオル":
                    this.volume = 100
                    break;
                case "団扇":
                    this.size = '普通サイズ'
                    break;
            }
        },
        computed: {
            types() {
                let types = [];
                return types;
            }
        },
        methods: {
            adjustType() {
                if (this.size === 'ミニサイズ' && this.type === '2本巻き') {
                    this.type = '1本巻き';
                }
            },
            selectTowelMaterial(material_type) {
                if(material_type == 'シャーリング'){
                    this.pattern = [
                        {
                            img: '/img/販売管理/柄/全面柄.jpg',
                            type: '全面柄'
                        },
                        {
                            img: '/img/販売管理/柄/中ベタ柄.jpg',
                            type: '中ベタ柄'
                        },
                        {
                            img: '/img/販売管理/柄/一般柄.jpg',
                            type: '一般柄'
                        }
                    ]
                    this.selected_towel_material = '総パイル'
                    this.selected_towel_material_img = '/img/販売管理/タオル生地/総パイル.jpg'

                    this.selected_pattern = '全面柄'
                    this.selected_pattern_img = '/img/販売管理/柄/全面柄.jpg'
                }else{
                    this.pattern = [
                        {
                            img: '/img/販売管理/柄/中ベタ柄.jpg',
                            type: '中ベタ柄'
                        },
                        {
                            img: '/img/販売管理/柄/一般柄.jpg',
                            type: '一般柄'
                        }
                    ]
                    this.selected_towel_material = '片毛'
                    this.selected_towel_material_img = '/img/販売管理/タオル生地/片毛.jpg'

                    this.selected_pattern = '中ベタ柄'
                    this.selected_pattern_img = '/img/販売管理/柄/中ベタ柄.jpg'
                }

                this.selected_towel_material = material_type;
                const type = this.towel_material.find(f => f.type === material_type);
                if (type) {
                    this.selected_towel_material_img = type.img;
                }
            },

            selectMaterial(material_type) {
                this.selected_material = material_type;
                const type = this.material.find(f => f.type === material_type);
                if (type) {
                    this.selected_material_img = type.img;
                }
            },
            selectPattern(pattern_type) {
                this.selected_pattern = pattern_type;
                const type = this.pattern.find(f => f.type === pattern_type);
                if (type) {
                    this.selected_pattern_img = type.img;
                }
            },
            selectMachining(machining_type){
                this.selected_machining = machining_type
                const type = this.machining.find(f => f.type === machining_type);
                if (type) {
                    this.selected_machining_img = type.img;
                }
            },
            selectFanType(fan_type){
                this.selected_fan = fan_type
                const type = this.fan_type.find(f => f.type === fan_type);
                if (type) {
                    this.selected_fan_img = type.img;
                }
            },

            PageTransition(val){
                //バリデーションチェック
                this.color_validate = false
                this.size_validate = false
                this.volume_validate = false

                const validationRules = {
                    '鯉口シャツ': ['size', 'volume'],
                    '団扇': ['size', 'type', 'volume'],
                    '巾着': ['volume'],
                    'タスキ': ['volume'],
                    'タスキ房': ['color', 'size', 'volume'],
                    'タオル': ['volume'],
                    '法被': ['size', 'volume'],
                    'チョッキ': ['size', 'volume'],
                    'ロンパース': ['size', 'volume'],
                }

                const rules = validationRules[this.goods_name];

                if (rules) {
                    for (let rule of rules) {
                        if (this[rule] === '' || this[rule] === 0) {
                            this[`${rule}_validate`] = true;
                            return;
                        }
                    }
                }

                let goodsDetail = {
                    goods_name: this.goods_name,
                    type: this.type,
                    volume: this.volume,
                    base: this.base,
                    amount: this.amount,
                    color: this.color,
                    size: this.size,

                    selected_towel_material: this.selected_towel_material,
                    selected_towel_material_img: this.selected_towel_material_img,

                    selected_material: this.selected_material,
                    selected_material_img: this.selected_material_img,

                    selected_pattern: this.selected_pattern,
                    selected_pattern_img: this.selected_pattern_img,

                    selected_machining: this.selected_machining,
                    selected_machining_img: this.selected_machining_img,

                    selected_fan: this.selected_fan,
                    selected_fan_img: this.selected_fan_img,
                }
                this.$_setGoodsDetail(goodsDetail)
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
            calculateAmount() {
                //鯉口シャツ
                if(this.goods_name == '鯉口シャツ'){
                    let base = 10000
                    this.volume >= 10 ? base = 8500 : base
                    let grading = 0
                    this.size == '別注' ? grading = 5000 : grading
                    let amount = base * this.volume + grading

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
                //団扇
                if(this.goods_name == '団扇'){
                    let base = 0
                    switch(this.selected_fan){
                        case '昇華団扇':
                            switch (this.size) {
                                case '普通サイズ':
                                    switch (this.type) {
                                        case '1本巻き':
                                            base = 3000
                                            break
                                        case '2本巻き':
                                            base = 3400
                                            break
                                    }
                                    break
                                case 'ミニサイズ':
                                    base = 2500
                                    break
                            }
                            break
                        case 'フルカラー団扇':
                            switch (this.size) {
                                case '普通サイズ':
                                    switch (this.type) {
                                        case '1本巻き':
                                            base = 2100
                                            break
                                        case '2本巻き':
                                            base = 2500
                                            break
                                    }
                                    break
                                case 'ミニサイズ':
                                    base = 1900
                                    break
                            }
                            break
                    }
                    let amount = base * this.volume

                    this.base = base.toLocaleString()
                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
                //巾着
                if(this.goods_name == '巾着'){
                    let base = 3200
                    let volume = this.volume
                    volume >= 30 ? base = 2700 : base
                    let amount = base * volume

                    this.base = base.toLocaleString()
                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
                //タスキ
                if(this.goods_name == 'タスキ'){
                    let base = 0
                    let volume = this.volume

                    switch (this.selected_machining) {
                        case '肉盛り刺繍':
                            volume >= 5 ? base = 22000 : base = 23000
                            break
                        case '刺繍':
                            volume >= 5 ? base = 18000 : base = 20000
                            break
                        case '貼り付け':
                            volume >= 5 ? base = 14000 : base = 15000
                            break
                        case 'プリント':
                            volume >= 5 ? base = 9000 : base = 10000
                            break
                    }
                    let amount = base * volume

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
                //タスキ房
                if(this.goods_name == 'タスキ房'){
                    let base = 0
                    let color = this.color
                    let size = this.size
                    let volume = this.volume

                    switch (color) {
                        case '白':
                            switch (size) {
                                case '3寸':
                                    volume >= 20 ? base = 850 : base = 1000
                                    break
                                case '4寸':
                                    volume >= 20 ? base = 1000 : base = 1200
                                    break
                            }
                            break
                        case 'カラー':
                            switch (size) {
                                case '3寸':
                                    volume >= 20 ? base = 1100 : base = 1300
                                    break
                                case '4寸':
                                    volume >= 20 ? base = 1200 : base = 1400
                                    break
                            }
                            break
                        case '白 ＋ カラー':
                            switch (size) {
                                case '3寸':
                                    volume >= 20 ? base = 1300 : base = 1500
                                    break
                                case '4寸':
                                    volume >= 20 ? base = 1500 : base = 1700
                                    break
                            }
                            break
                        case 'カラー ＋ カラー':
                            switch (size) {
                                case '3寸':
                                    volume >= 20 ? base = 1600 : base = 1800
                                    break
                                case '4寸':
                                    volume >= 20 ? base = 1800 : base = 2000
                                    break
                            }
                            break
                    }
                    let amount = base * this.volume;

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
                //タオル
                if(this.goods_name == 'タオル'){
                    let base = 0
                    let volume = this.volume
                    let material = this.selected_towel_material
                    let pattern = this.selected_pattern
                    switch (material) {
                        case 'シャーリング':
                            switch (pattern){
                                case '全面柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 510
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 460
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 420
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 415
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 410
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 405
                                            break
                                        case volume >= 1000:
                                            base = 395
                                            break
                                    }
                                    break
                                case '中ベタ柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 410
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 350
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 320
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 310
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 305
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 300
                                            break
                                        case volume >= 1000:
                                            base = 295
                                            break
                                    }
                                    break
                                case '一般柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 405
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 340
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 310
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 305
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 300
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 295
                                            break
                                        case volume >= 1000:
                                            base = 290
                                            break
                                    }
                                    break
                            }
                            break
                        case '片毛':
                            switch (pattern){
                                case '中ベタ柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 340
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 310
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 290
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 280
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 270
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 260
                                            break
                                        case volume >= 1000:
                                            base = 250
                                            break
                                    }
                                    break
                                case '一般柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 310
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 280
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 260
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 250
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 240
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 230
                                            break
                                        case volume >= 1000:
                                            base = 220
                                            break
                                    }
                                    break
                            }
                            break
                        case '総パイル':
                            switch (pattern){
                                case '中ベタ柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 270
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 260
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 250
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 240
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 220
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 210
                                            break
                                        case volume >= 1000:
                                            base = 190
                                            break
                                    }
                                    break
                                case '一般柄':
                                    switch (true) {
                                        case volume >= 100 && volume < 200:
                                            base = 240
                                            break
                                        case volume >= 200 && volume < 300:
                                            base = 230
                                            break
                                        case volume >= 300 && volume < 400:
                                            base = 225
                                            break
                                        case volume >= 400 && volume < 500:
                                            base = 220
                                            break
                                        case volume >= 500 && volume < 800:
                                            base = 210
                                            break
                                        case volume >= 800 && volume < 1000:
                                            base = 200
                                            break
                                        case volume >= 1000:
                                            base = 180
                                            break
                                    }
                                    break
                            }
                            break
                    }
                    let amount = base * volume;

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }

                //法被
                if(this.goods_name == '法被'){
                    let base = 19000
                    this.volume >= 5 ? base = 16500 : base

                    let grading = 0
                    this.size == '別注' ? grading = 5000 : grading

                    let amount = base * this.volume + grading

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }

                //チョッキ
                if(this.goods_name == 'チョッキ'){
                    let base = 6500
                    this.volume >= 5 ? base = 6000 : base

                    let grading = 0
                    this.size == '別注' ? grading = 5000 : grading

                    let amount = base * this.volume + grading

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }

                //ロンパース
                if(this.goods_name == 'ロンパース'){
                    let base = 6500
                    this.volume >= 5 ? base = 6000 : base

                    let amount = base * this.volume

                    this.base = base.toLocaleString()

                    this.amount = amount
                    amount = amount.toLocaleString()
                    return amount;
                }
            }
        }
    }
</script>

<style scoped>
    h2 {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .introduction {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
    }
    .main {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 5% 4%;
    }
    .select {
        margin: 0 0 10%;
    }
    .color_detail {
        color: darkgrey;
        font-size: 14px;
    }
    .volume_detail {
        color: darkgrey;
        font-size: 14px;
    }
    .base {
        display: flex;
        height: 60px;
        margin: 5% 0;
    }
    .base_detail {
        width: calc(100% - 130px);
    }
    .amount {
        display: flex;
        height: 60px;
    }
    .amount_detail {
        width: calc(100% - 130px);
    }
    .iPhoneBtn {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: whitesmoke;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: 5px;
        width: 130px;
        margin: 0;
        padding: 14px 0 0 13px;
    }
    .AndroidBtn {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: whitesmoke;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: 5px;
        width: 130px;
        margin: 0;
        padding: 12px 0 0 5px;
    }
    #img {
        width: 94%;
        padding: 4% 2%;
    }
    .selected {
        border: 2px solid blue; /* 選択中の画像に青いボーダーを追加する */
    }
    .not-selected {
        border: none; /* 選択中でない画像のボーダーを消す */
    }
    #size {
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 40%;
        margin: 5% 0;
    }
    #color {
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 40%;
        margin: 5% 0;
    }
    .input{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 40%;
        padding: 1%;
    }
    #volume {
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 40%;
        margin: 5% 0;
    }
    .validate {
        color: red;
    }
    </style>