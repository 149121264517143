<!--
  Title        : メインページ
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div class="top">
            <div class="our_firm">
                <swiper :options="swiperOptions">
                    <swiper-slide v-for="(img, index) in our_film_img" :key="index">
                        <img class="our_firm_img" :src="img" @click="bannerPageTransition(index)">
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>

        <!--地車-->
        <div class="main" v-show="festival_type == 1 || festival_type == 3">
            <h2>だんじり総合情報</h2>
            <div class="row">
                <div class="content">
                    <v-ons-button class="btn" id="btn1" @click="PageTransition('select')">
                        <div class="big_title">だんじり追跡</div>
                        <div class="small_title">地区から選ぶ>></div>
                    </v-ons-button>
                </div>
                <div class="content">
                    <v-ons-button class="btn" id="news" @click="PageTransition('allNews')">
                        <span id="notification_count" v-show="unreadFlg" class="notification notification--material">!</span>
                        <div class="big_title">お知らせ</div>
                        <div class="small_title">町一覧で見る>></div>
                    </v-ons-button>
                    <v-ons-button class="btn" id="schedule" @click="PageTransition('schedule')">
                        <div class="big_title">祭礼日程表</div>
                        <div class="small_title">一覧で見る>></div>
                    </v-ons-button>
                </div>
            </div>

            <v-ons-button class="btn" id="btn3" @click="PageTransition('allSponsor')">
                <div>協賛スポンサー</div>
            </v-ons-button>
        </div>

        <!--布団太鼓-->
        <div class="main" v-show="festival_type == 2">
            <h2>布団太鼓総合情報</h2>
            <div class="row">
                <div class="content">
                    <v-ons-button class="btn" id="futon" @click="PageTransition('select')">
                        <div class="big_title">布団太鼓追跡</div>
                        <div class="small_title">地区から選ぶ>></div>
                    </v-ons-button>
                </div>
                <div class="content">
                    <v-ons-button class="btn" id="news" @click="PageTransition('allNews')">
                        <span id="notification_count" v-show="unreadFlg" class="notification notification--material">!</span>
                        <div class="big_title">お知らせ</div>
                        <div class="small_title">町一覧で見る>></div>
                    </v-ons-button>
                    <v-ons-button class="btn" id="schedule" @click="PageTransition('schedule')">
                        <div class="big_title">祭礼日程表</div>
                        <div class="small_title">一覧で見る>></div>
                    </v-ons-button>
                </div>
            </div>

            <v-ons-button class="btn" id="btn3" @click="PageTransition('allSponsor')">
                <div>協賛スポンサー</div>
            </v-ons-button>
        </div>

        <div class="content_background">
            <div id="content_background" @click="PageTransition('goodsSelection')">
                <img id="btn4" src="/img/our_film/goods_banner.png">
            </div>
        </div>

        <div class="overlay" v-show="alert">
            <div class="description_content" slot="title">
                <carousel
                        :per-page="1"
                        :pagination-enabled="true"
                        :pagination-size="8"
                        :pagination-padding="5"
                        :autoplay="false"
                        :loop="false"
                        :autoplayHoverPause="true">
                    <slide v-for="(val, index) in alertRes" :key="index">
                        <div class="slide">
                            <img class="description_img" :src="val.img">
                            <h2 class="title">{{ val.title }}</h2>
                            <div class="description">
                                {{ val.description }}
                            </div>
                        </div>
                    </slide>
                </carousel>

                <div class="description_btn">
                    <div class="check">
                        <v-ons-checkbox style="padding: 2px 0 0" v-model="check" modifier="material"></v-ons-checkbox>今後表示しない
                    </div>
                    <div class="skip" @click="alert_close">
                        スキップする
                    </div>
                </div>
            </div>
        </div>

        <!--  エラーダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertErrorVisible">
            <span slot="title">ただいまメンテナンス中です</span>
            申し訳ございませんが、今しばらくお待ちください。
            <template slot="footer">
                <v-ons-alert-dialog-button @click="alertErrorVisible = false">Ok</v-ons-alert-dialog-button>
            </template>
        </v-ons-alert-dialog>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "Main",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data() {
            return {
                festival_type: this.$_festivalType(),
                showChild: true,
                check: false,
                alertErrorVisible: false,
                pagination: false,
                alert: false,
                alertRes: [
                    {
                        img: "img/popup/main/tsuiseki.png",
                        title: "追跡について",
                        description: "追跡を押すと地区選択画面に移動します。\n" +
                                     "地区選択画面から連合を選択して頂きます。\n" +
                                     "連合選択すると連合の画面に移動し、祭り本番時であればその連合の地車、太鼓台がリアルタイムでマップ上に表示されます。",
                    },
                    {
                        img: "img/popup/main/news.png",
                        title: "お知らせ機能について",
                        description: "地車＆太鼓アプリに加入していただいている町から投稿されたお知らせをご確認いただけます。\n" +
                                     "さらに自身のお好きな町をお気に入り登録していただくことでその町の最新情報をプッシュ通知で受けることができます。",
                    },
                    {
                        img: "img/popup/main/sairei.png",
                        title: "祭礼日程表について",
                        description: "祭礼日程、行事日程をご確認いただけます。\n" +
                                     "これによりいつどこで祭りが開催されているのかが分かります。",
                    },
                    {
                        img: "img/popup/main/sponsor.png",
                        title: "協賛スポンサーについて",
                        description: "地車＆太鼓アプリに応援、協力、協賛していただいている。\n" +
                                     "スポンサー様を一覧でご確認いただけます。\n" +
                                     "このアプリはスポンサー様の協力、応援のおかげで運営し、成り立っております。",
                    },
                    {
                        img: "img/popup/main/sales.png",
                        title: "祭礼グッズ制作 \n" + "販売について",
                        description: "アプリ内でタオル、タスキ、団扇等を簡単・低価格・高品質で注文いただけます。\n" +
                                     "手間だった作業もアプリ内で解決できます。",
                    },
                ],
                unreadFlg: false,
                swiperOptions: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    loop: true,
                    autoplay: {
                        delay: 5000, // 途切れなくループ
                        reverseDirection: false,
                    },
                },
                our_film_img:[
                    "/img/our_film/FMOsaka_banner.png",
                    "/img/our_film/townjoining_banner.png",
                    "/img/our_film/sponsor_banner.png",
                    "/img/our_film/goods_sales_banner.png",
                ],
            }
        },
        mounted() {
            let self = this   //thenの中からdataを操作するため
            let apiVal = {
                uuid: this.$_uuid(),
                type: 'main'
            }
            let descriptionFlg = this.$_mixAPI_getDescriptionFlg(apiVal)
            descriptionFlg.then(function(val){
                console.log(val)
                self.alert = val.description_flg
            }).catch((err)=>{   //通信エラー時
                console.log(err)
            });
            let unreadApiVal = {
                uuid: this.$_uuid(),
                festival_type: this.festival_type
            }
            let unread = this.$_mixAPI_logMonitoring(unreadApiVal)
            unread.then(function(val){
               self.unreadFlg = val.unread_flg
            }).catch((err)=>{   //通信エラー時
                console.log(err)
                self.unreadFlg = false
            });
        },
        methods: {
            bannerPageTransition(val){
                this.$_setPage('main')
                switch (val) {
                    case 0:
                        return(
                            this.$_setSponsor_id(118),
                            //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                            this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
                        );
                    case 3:
                        return(
                            //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                            this.$_SetmixPC_CurrentPage_Key('goodsSelection')
                        )
                    default:
                        return(
                            //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                            this.$_SetmixPC_CurrentPage_Key('inquiry')
                        )
                }
            },
            alert_close(){
                this.alert = false
                if(this.check == true){
                    let apiVal = {
                        uuid: this.$_uuid(),
                        type: 'main',
                    }
                    this.$_mixAPI_updateDescriptionFlg(apiVal)
                }
            },
            PageTransition(val){
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            }
        }
    }
</script>

<style scoped>
    .background {
        background: black;
    }
    .top {
        background-image: url("/img/parts/スライダー-背景.jpg");
        background-size: 100% 260px;
        background-repeat: no-repeat;
        width: 100%;
        height: 250px;
    }
    .our_firm{
        border-radius: 20px;
        top: 10px;
        width: calc(100% - 40px);
        padding: 20px;
    }
    .our_firm_img{
        width: 100%;
        height: 210px;
    }
    .main {
        background-color: white;
        border-radius: 20px;
        width: 92%;
        margin: 3%;
        padding: 1%;
    }
    h2 {
        font-size: 20px;
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        text-align: left;
        margin: 2% 0 0 3%;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }
    .content {
       width: 50%;
    }
    .btn {
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
        -1px 1px 0 #000, 1px -1px 0 #000,
        0 1px 0 #000,  0 -1px 0 #000,
        -1px 0 0 #000, 1px 0 0 #000;
        background: url("/img/parts/金色屏風-背景.jpg");
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        border-radius: 10px;
    }
    #btn1 {
        background-image: url("/img/parts/だんじり表紙2.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 60%, 1000%;
        background-repeat: no-repeat, repeat;
        width: 90%;
        height: 190px;
        margin: 10px 5%;
        padding: 100px 10px 4px;
    }
    #futon {
        background-image: url("/img/parts/taiko_2024.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 80%, 1000%;
        background-repeat: no-repeat, repeat;
        width: 90%;
        height: 190px;
        margin: 10px 5%;
        padding: 100px 10px 4px;
    }
    #news {
        background-image: url("/img/parts/メガホン-透過.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 60%, 1000%;
        background-repeat: no-repeat, repeat;
        text-align: left;
        overflow: visible;
        width: 94%;
        height: 90px;
        margin: 10px 3% 0 3%;
        padding: 20px 10px 4px;
    }
    #schedule {
        background-image: url("/img/parts/カレンダー-透過.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 70%, 1000%;
        background-repeat: no-repeat, repeat;
        text-align: left;
        width: 94%;
        height: 90px;
        margin: 10px 3% 0 3%;
        padding: 20px 10px 4px;
    }
    #btn3 {
        font-size: 20px;
        margin: 3% 0 5%;
        padding: 8px 0 0;
        width: 95%;
        height: 50px;
    }
    .content_background {
        background-color: black;
        border-radius: 20px;
    }
    #content_background {
        position: relative;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        width: 100%;
    }
    #content_background:active {
        opacity: 0.7;
    }
    #btn4 {
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        border-radius: 10px;
        width: 92%;
        margin: 10px 4%;
    }
    .big_title {
        text-align: left;
        font-size: 20px;
    }
    .small_title {
        text-align: right;
        font-size: 14px;
    }
    #notification_count{
        box-shadow: inset 5px 5px 8px 0 rgba(0, 0, 0, .1);
        position: absolute;
        font-size: 20px;
        top: -7px;
        right: -7px;
        width: 30px;
        height: 30px;
        padding: 5px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 0 4%;
        font-size: 95%;
    }
    h2 span {
        border-bottom: #b58012 2px solid;
        padding: 0 5% 2%;
    }

    .overlay {
        z-index: 2;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .description_content {
        background-color: #f4f4f4;
        width: 90%;
        max-height: 100%;
        overflow-y: auto;
    }
    .slide {
        text-align: left;
    }
    .description_img {
        background-color: #f79b05;
        width: 100%;
    }
    .title {
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 20px;
        width: 100%;
    }
    .description {
        white-space: pre-wrap;
        word-wrap: break-word;
        margin: 1% 3%;
        font-size: 16px;
    }
    .description_btn {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        margin: 1% 3%;
    }
    .check {
        font-size: 16px;
        width: 60%;
    }
    .skip {
        font-size: 16px;
        text-align: right;
        color: #3cb8c8;
        width: 40%;
    }
</style>