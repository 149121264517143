<!--
  Title        : 祭り選択画面
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar v-if="showChild" :action="toggleMenu"></Toolbar>

        <BottomToolbar></BottomToolbar>

        <div class="new_arrivals">
            <h2 class="new_arrivals_title">
                <span>新着情報</span>
            </h2>
            <div class="new_arrivals_contents">
                <ul v-for="(value, index) in newArrivalRes.new_arrival" :key="index">
                    <li v-show="index == 0">
                        <span>
                            {{ value.publication_date }}掲載
                        </span>
                        <br>
                        <span class="update_details">
                            {{ value.information }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="new_arrivals_link">
                <span @click="newArrivals()">>>新着情報一覧</span>
            </div>
        </div>

        <div class="main">
            <h2>祭り選択</h2>
            <div class="content">
                <div class="row" v-for="festival in festivals" :key="festival.id">
                    <v-ons-button class="btn" :id="festival.btnId" @click="PageTransition(festival.id)">
                        <div class="big_title">{{ festival.title }}</div>
                        <div class="small_title">専用ページに>></div>
                    </v-ons-button>
                </div>
            </div>
        </div>

        <div class="content_background">
            <div id="content_background">
                <a class="sns_url" href="https://technoeye.com/omatsurigps" target="_blank">
                    <img id="btn2" src="/img/parts/HP宣伝バナー(こちらをタップ).png">
                </a>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "Top",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return {
                showChild: true,
                newArrivalRes: [],
                festivals: [
                    { id: 1, title: "だんじり", btnId: "jiguruma" },
                    { id: 2, title: "布団太鼓", btnId: "futon" }
                ]
            }
        },
        mounted() {
            let self = this   //thenの中からdataを操作するため
            let newArrivals = this.$_mixAPI_UpTo3Notifications()
            newArrivals.then(function(val){
                self.newArrivalRes = val
            })
        },
        methods: {
            newArrivals(){
                this.$_setPage('')
                return(
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('newArrivals')
                );
            },
            PageTransition(val){
                this.$_setfestivalType(val)
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === "main");
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            }
        }
    }
</script>

<style scoped>
    .background {
        background: black;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 0 2%;
        font-size: 95%;
    }
    h2 span {
        border-bottom: #b58012 2px solid;
        padding: 0 5% 2%;
    }
    .new_arrivals {
        background: rgba(255, 255, 255, 0.9);
        margin: 3%;
    }
    .new_arrivals_title{
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        margin: 0 0 3%;
        padding: 3% 0;
    }
    .new_arrivals_contents{
        text-align: left;
        margin: 0 3%;
    }
    .new_arrivals_link {
        color: blue;
        text-align: right;
        padding: 0 10px 3px 0;
    }
    .update_details {
        border-bottom: rgba(0, 0, 0, 0.5) 1px solid;
    }

    .main {
        background-color: white;
        border-radius: 20px;
        width: 92%;
        margin: 3%;
        padding: 1%;
    }
    h2 {
        font-size: 20px;
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        text-align: left;
        margin: 2% 0 0 3%;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }
    .row {
        width: 50%;
    }
    .btn {
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
        -1px 1px 0 #000, 1px -1px 0 #000,
        0 1px 0 #000,  0 -1px 0 #000,
        -1px 0 0 #000, 1px 0 0 #000;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        border-radius: 10px;
        width: 90%;
        height: 150px;
        margin: 10px 5%;
    }
    #jiguruma {
        background-image: url("/img/parts/だんじり表紙2.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 60%, 1000%;
        background-repeat: no-repeat, repeat;
        width: 90%;
        height: 180px;
        margin: 10px 5%;
        padding: 75px 10px 4px;
    }
    #futon {
        background-image: url("/img/parts/taiko_2024.png"), url("/img/parts/金色屏風-背景.jpg");
        background-position: right bottom, center top;
        background-size: 75%, 1000%;
        background-repeat: no-repeat, repeat;
        width: 90%;
        height: 180px;
        margin: 10px 5%;
        padding: 75px 10px 4px;
    }
    .big_title {
        text-align: left;
        font-size: 20px;
    }
    .small_title {
        text-align: right;
        font-size: 16px;
    }

    .content_background {
        background-color: black;
        border-radius: 20px;
    }
    #content_background {
        position: relative;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        width: 100%;
    }
    #content_background:active {
        opacity: 0.7;
    }
    .mineno {
        position: relative;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        width: 100%;
    }
    #btn2 {
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        border-radius: 10px;
        width: 92%;
        margin: 0 4% 15px;
    }
</style>