<!--
  Title        : 全町お知らせ
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div class="main">
            <div class="title">
                <h2 id="title">-全地域-</h2>
                <div id="title">お知らせ一覧</div>
            </div>

            <div class="body">
                <div v-for="(val, index) in res" :key="index">
                    <div class="row" @click="news(val.towns.town_id)">
                        <div class="content">
                            <div class="city">
                                &nbsp;{{ val.towns.city_name }}&nbsp;&nbsp;{{ val.towns.festival_name }}&nbsp;
                            </div>
                            <div class="new" v-show="val.unread_flg">
                                &nbsp;new&nbsp;
                            </div>
                        </div>
                        <div class="content">
                            <div class="town">
                                {{ val.towns.town_name }}からのお知らせ
                            </div>
                            <v-ons-icon
                                    class="icon"
                                    size="20px"
                                    icon="chevron-right">
                            </v-ons-icon>
                        </div>
                        <div class="content">
                            <div class="update_time">
                                {{ val.latest_at }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "AllNews",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar
        },
        data() {
            return {
                res: null,
            }
        },
        mounted() {
            let self = this
            let apiVal = {
                uuid: this.$_uuid(),
                festival_type: this.$_festivalType(),
            }
            let getResultAPI = this.$_mixAPI_getAllNews(apiVal)
            getResultAPI.then(function(val){
                self.res = val
                console.log(val)
            }).catch((err)=>{
                //通信エラー時
                console.log(err)
            });
        },
        methods: {
            news(val){
                this.$_setTownID(val)
                this.$_SetmixPC_CurrentPage_Key('newsDetail')
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background: #fffbea;
        border-radius: 10px;
        width: 92%;
        margin: 3%;
        padding: 1%;
    }
    .body{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        width: 94%;
        margin: 1% 3%;
    }
    .row {
        text-align: left;
        background-color: white;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        border-radius: 10px;
        width: 88%;
        margin: 1% 3% 8%;
        padding: 4% 3% 2%;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
    }
    .city {
        font-size: 14px;
        background-color: #f79b05;
        width: auto;
    }
    .new {
        font-size: 14px;
        color: white;
        background-color: red;
        margin-left: auto;
    }
    .town {
        font-size: 20px;
        width: calc(100% - 20px);
    }
    .icon {
        padding: 4px 0;
        width: 20px;
    }
    .update_time {
        margin-left: auto;
    }
    .title {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        white-space: pre-wrap;
        word-break: break-all;
        margin: 5px 2% 5px;
    }
    #title{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 1%;
        font-size: 20px;
    }
</style>