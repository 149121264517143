<!--
  Title        : APP
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <div id="app">
    <Splitter />
    <v-ons-alert-dialog modifier="rowfooter"
                        :visible.sync="alertUpdateVisible">
      <span slot="title">お知らせ</span>
      <span>最新バージョンへのアップデートが必要です。</span>
      <template slot="footer">
        <v-ons-alert-dialog-button>
          <a href="https://play.google.com/store/apps/details?id=com.technoeye.jiguruma">こちらからアップデート</a>
        </v-ons-alert-dialog-button>
      </template>
    </v-ons-alert-dialog>
  </div>
</template>

<script>
import Splitter from './components/Common/Splitter'
import isMobile from "ismobilejs";

export default {
  name: 'App',
  data(){
    return{
      alertUpdateVisible: false,

      deviceTokenReceived: false,
      uuidReceived: false
    }
  },
  created() {
    // //dummy_uuid
    // this.$_setuuid('DUMMY-UUID-0000-9999')
    // //dummy_deviceToken
    // this.$_setDeviceToken('80835c1f5cbd3d30cb5af50a543643713ddc37c2e16cd0e2a2b920914dd03954')
    // //dummy_terminal
    // this.$_setTerminal('Android')
    // // this.$_setTerminal('iPhone')
    //
    // // device_token を API へ登録
    // const device = {
    //   device_token: this.$_deviceToken(),
    //   uuid: this.$_uuid(),
    //   terminal: this.$_Terminal()
    // };
    // this.$_mixAPI_registDeviceToken(device);

    // スマホからアクセスするための関数登録
    if (isMobile(window.navigator).apple.device) {
      this.$_setTerminal('iPhone')
      window.webkit.messageHandlers.getUUID.postMessage("");
    } else {
      // //スマホへUUIDを要求
      this.$_setTerminal('Android')
      window.getUUID.postMessage("");
      if(window.getVersion.version() === 18){
        this.alertUpdateVisible = false
      }else{
        this.alertUpdateVisible = false
      }
    }

    // スマホから取得したDeviceTokenを広域変数へセット
    (window).registDeviceToken = (val) =>{
      this.$_setDeviceToken(val);
    };
    // スマホから取得したUUIDを広域変数へセット
    (window).registUUID = (val) => {
      this.$_setuuid(val);
    }

    // スマホからのデバイス情報受け取り
    window.registDeviceToken = (val) => {
      this.$_setDeviceToken(val);
      this.deviceTokenReceived = true;
    };
    window.registUUID = (val) => {
      this.$_setuuid(val);
      this.uuidReceived = true;
    };
    // 3秒後にデバイス情報が取得できていなかったらダミー値をセット
    setTimeout(() => {
      if (!this.deviceTokenReceived) {
        //dummy_deviceToken
        this.$_setDeviceToken('80835c1f5cbd3d30cb5af50a543643713ddc37c2e16cd0e2a2b920914dd03954')
      }
      if (!this.uuidReceived) {
        //dummy_uuid
        this.$_setuuid('DUMMY-UUID-0000-9999')
      }

      // device_token を API へ登録
      const device = {
        device_token: this.$_deviceToken(),
        uuid: this.$_uuid(),
        terminal: this.$_Terminal()
      };
      this.$_mixAPI_registDeviceToken(device);
    }, 3000); // 3秒後にチェック

    this.mixPC_firstPageSet;
  },
  components: {
    Splitter
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Sawarabi+Mincho");
#app {
  background-image: url("/img/bg_sayagata.jpg");
  background-color: black;
  font-family: Sawarabi Mincho, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>